import React from "react";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import "./footer.scss";

const icons = {
  facebook: "fab fa-facebook",
  instagram: "fab fa-instagram",
  twitter: "fab fa-twitter",
  google: "fab fa-google",
  website: "fa fa-globe",
  youtube: "fab fa-youtube",
};

const Footer = ({ data = {} }) => {
  const { blocks = [], links, contentId } = data;
  const blockElems =
    blocks &&
    blocks.map((block, index) => {
      return (
        <div
          className="col-12 col-sm-6 col-md-4"
          key={`footer_blocks_${index}`}
        >
          <h3>
            <strong>{block.title}</strong>
          </h3>
          {ReactHtmlParser(block.description)}
        </div>
      );
    });

  return (
    <footer id={contentId} className="fdb-block footer-large bg-dark">
      <div className="container">
        <div className="row align-items-top text-center text-md-left">
          {blockElems}
          <div className="col-12 col-md-4 mt-5 mt-md-0 text-center text-md-right">
            {links &&
              links.map((link, index) => {
                return (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={link.link}
                    className="mx-2 footer-social-link"
                    key={`social_link_${index}`}
                  >
                    <i className={icons[link.type]} aria-hidden="true"></i>
                  </a>
                );
              })}

            <p className="text-md-right mt-3">
              {/*<img alt="logo" src={imageUrl(page.logo)} height="40" />*/}
            </p>
            <p className="text-md-right mt-3">
              © {moment().format("YYYY")}. All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
