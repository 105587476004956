import React from 'react';
import classNames from "classnames";

const renderComposers = (composers) => (
    composers ? <p>{composers}</p> : null
)

const ConcertSummary = ({concert = {}}) => {

    const {name, date, dateText, composers, artists = []} = concert;

    const dateTextClass = classNames({'mb-0' : !!composers})

    return (
       <>
           <p className="h4"><strong>{name}</strong></p>
           <p className={dateTextClass}>{dateText ? dateText: date }</p>
           {renderComposers(composers)}
           {
               artists
                   .filter(artist => artist && artist.visible === true)
                   .map((artist, index) =><p className="artist" key={`artist_${index}`}><strong>{artist.name}</strong> {artist.role}</p>)
           }
       </>
    );
};

export default ConcertSummary;