
import React from 'react';
import BenefitItem from "./benefit-item";

const Benefits = ({benefits = []}) => (
    <div className="row text-left">
        {
            benefits.map((benefit, index)=> <BenefitItem benefit={benefit} key={`benefit_${index}`}/>)
        }
    </div>
);

export default Benefits;