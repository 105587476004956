import React from "react";
import './button.scss';

const SubscriptionButton = ({label, link, color}) => (
    <div className="floating-button">
        <a
            className="btn btn-default ml-md-3"
            style={{
                backgroundColor:color
            }}
            href={link}
        >
            {label}
        </a>
    </div>
);

export default SubscriptionButton;