import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { v4 as uuidv4 } from "uuid";

import ConcertSummary from "./concertSummary";
import SeriesDetail from "./series-detail";

import "./series.scss";

const Concerts = ({ concerts = [] }) => {
  return concerts.map((concert, index) => {
    return (
      <React.Fragment key={uuidv4()}>
        {index > 0 ? <hr /> : null}
        <ConcertSummary concert={concert} />
      </React.Fragment>
    );
  });
};

const PAGINATION_STEP = 3;

const PrevButton = ({ color, onClick = {} }) => (
  <button
    className="btn btn-default btn-circle"
    onClick={onClick}
    style={{ backgroundColor: color }}
  >
    <i className="carousel-control-prev-icon"></i>
    {/*<span aria-hidden="true" className="carousel-control-prev-icon"></span>*/}
  </button>
);

const NextButton = ({ color, onClick = {} }) => (
  <button
    className="btn btn-default btn-circle"
    onClick={onClick}
    style={{ backgroundColor: color }}
  >
    <i className="carousel-control-next-icon"></i>
  </button>
);

const Series = ({ data = {} }) => {
  const { title, subtitle, items, page, contentId } = data;

  const [modalShow, setModalShow] = useState(false);
  const [currentSeries, setCurrentSeries] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(0);

  useEffect(() => {
    setMaxPage(Math.ceil(items.length / PAGINATION_STEP));
  }, [items]);

  const series = items.slice(
    (currentPage - 1) * PAGINATION_STEP,
    currentPage * PAGINATION_STEP
  );

  const renderSeriesItem = (seriesItem) => {
    const style = {
      backgroundColor: seriesItem.color,
      color: "#ffffff",
    };

    return (
      <div
        className="col-12 col-sm-10 col-md-8 col-lg-4 mb-3 text-left"
        key={uuidv4()}
      >
        <div className="rounded">
          <div className="p-1 text-center" style={style}>
            <h2 className="font-weight-light serie-title">
              {seriesItem.title}
            </h2>
            {seriesItem.subtitle && (
              <h4 className="font-weight-light serie-subtitle pl-1 pr-1">
                {seriesItem.subtitle}
              </h4>
            )}
          </div>

          <div className="bg-gray p-3">
            {seriesItem.description && ReactHtmlParser(seriesItem.description)}
            {seriesItem.description && <hr />}
            <Concerts concerts={seriesItem.concertList} />
            <p className="text-center pt-3">
              <button
                className="btn"
                style={style}
                onClick={() => {
                  setCurrentSeries(seriesItem);
                  setModalShow(true);
                }}
              >
                {seriesItem.showMoreText
                  ? seriesItem.showMoreText
                  : "Show More"}
              </button>
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderSliderButtons = (items) =>
    items.length > 3 ? (
      <div className="d-flex justify-content-between mt-3">
        <div>
          <PrevButton color={page.color} onClick={handlePrev} />
        </div>

        <div>
          <NextButton color={page.color} onClick={handleNext} />
        </div>
      </div>
    ) : null;

  const seriesItems = series.map((item) => renderSeriesItem(item));

  const handlePrev = () => currentPage > 1 && setCurrentPage(currentPage - 1);
  const handleNext = () =>
    currentPage < maxPage && setCurrentPage(currentPage + 1);

  return (
    <section id={contentId} className="fdb-block">
      <div className="container">
        <div className="row align-items-center text-center">
          <div className="col">
            <h2 className="block-title">{title}</h2>
            <h4 className="block-subtitle">{subtitle}</h4>
          </div>
        </div>
        {renderSliderButtons(items)}
        <div className="row mt-3 justify-content-center">{seriesItems}</div>
      </div>
      <SeriesDetail
        show={modalShow}
        onHide={() => setModalShow(false)}
        series={currentSeries}
      />
    </section>
  );
};

export default Series;
