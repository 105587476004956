import React from "react";
import ReactHtmlParser from "react-html-parser";
import { imageUrl } from "../../utils/util";
const Team = ({ data = {} }) => {
  const { title, subtitle, description, teamMembers, contentId } = data;
  return (
    <section id={contentId} className="fdb-block">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <h2 className="block-title">{title}</h2>
            <h4 className="block-subtitle">{subtitle}</h4>
            <div className="block-detail mt-5">
              {ReactHtmlParser(description)}
            </div>
          </div>
        </div>
        {/*<div className="row">*/}
        {/*    <div className="col-12 text-center">*/}
        {/*        <img alt="image" className="img-fluid" src={cover} />*/}
        {/*    </div>*/}
        {/*</div>*/}
        <div className="row pt-5">
          <div className="col-6 offset-3">
            <img
              alt="cover"
              className="img-fluid"
              src={imageUrl(teamMembers)}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
