import React from "react";
import classNames from "classnames";
import ReactHtmlParser from "react-html-parser";

import { imageUrl } from "../../utils/util";

const SectionFill = ({ section }) => {
  const {
    title,
    subtitle,
    description,
    cover,
    coverPosition = "RIGHT",
    shortCover,
    links = [],
    page,
    contentId,
  } = section;

  const buttons =
    links.length > 0 ? (
      <p className="mt-4">
        {links.map((item, index) => {
          const buttonClass = classNames("btn btn-default", {
            "ml-3": index > 0,
          });
          return (
            <a
              className={buttonClass}
              target="_blank"
              style={{
                backgroundColor: page.color,
              }}
              href={item.link}
              key={`link_${index}`}
            >
              {item.label}
            </a>
          );
        })}
      </p>
    ) : null;

  const isCoverRightPositioned = coverPosition === "RIGHT";
  const imageType = isCoverRightPositioned ? "right" : "left";
  const imageClass = classNames({ [`col-fill-${imageType}`]: true });
  const containerClass = classNames("container", {
    "py-5": isCoverRightPositioned,
  });
  const rowClass = classNames("row", {
    "justify-content-end": !isCoverRightPositioned,
  });

  return (
    <section className="fdb-block" id={contentId}>
      <div
        className={imageClass}
        style={{
          backgroundImage: `url(${imageUrl(cover)})`,
        }}
      ></div>

      <div className={containerClass}>
        <div className={rowClass}>
          <div className="col-12 col-md-5 text-center">
            <h2 className="block-title">{title}</h2>
            <h4 className="block-subtitle">{subtitle}</h4>
            <div className="block-detail mt-5">
              {ReactHtmlParser(description)}
              {shortCover && (
                <img
                  alt="pricing"
                  className="img-fluid"
                  src={imageUrl(shortCover)}
                />
              )}
              {buttons}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionFill;
