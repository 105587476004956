import React from "react";
import BannerItem from "./banner-item";
import { Carousel } from "react-bootstrap";

import "./banner.scss";

const Banners = ({ data = {} }) => {
  const { banners = [] } = data;

  const controls = banners.length > 1;

  return (
    <Carousel interval={null} indicators={controls} controls={controls}>
      {banners.map((banner, index) => (
        <Carousel.Item className="banner-item" key={`banner_${index}`}>
          <BannerItem banner={banner} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default Banners;
