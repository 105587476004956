import React from "react";
import ReactHtmlParser from "react-html-parser";
import { imageUrl } from "../../utils/util";
import classNames from "classnames";

const SectionDefault = ({ section }) => {
  const {
    title,
    subtitle,
    bgColor,
    titleColor,
    description,
    cover,
    coverPosition = "LEFT",
    shortCover,
    links = [],
    page,
    contentId,
  } = section;

  const styles = bgColor
    ? {
        backgroundColor: bgColor,
        color: titleColor,
      }
    : null;

  const buttons =
    links.length > 0 ? (
      <p className="mt-4">
        {links.map((item, index) => {
          const buttonClass = classNames("btn btn-default", {
            "ml-3": index > 0,
          });
          return (
            <a
              className={buttonClass}
              target="_blank"
              style={{
                backgroundColor: page.color,
              }}
              href={item.link}
              key={`link_${index}`}
            >
              {item.label}
            </a>
          );
        })}
      </p>
    ) : null;

  return (
    <section id={contentId} className="fdb-block" style={styles}>
      <div className="container">
        <div className="row align-items-center text-center">
          {coverPosition === "LEFT" ? (
            <div className="col-12 col-md-6 mb-4 mb-md-0">
              <img alt="cover" className="img-fluid" src={imageUrl(cover)} />
            </div>
          ) : null}

          <div className="col-12 col-md-6 col-lg-5 ml-md-auto text-left">
            <h2 className="block-title">{title}</h2>
            <h4 className="block-subtitle">{subtitle}</h4>
            <div className="block-detail mt-5">
              {ReactHtmlParser(description)}
              {shortCover && (
                <img
                  alt="second_image"
                  className="img-fluid mt-5"
                  src={imageUrl(shortCover)}
                />
              )}
              {buttons}
            </div>
          </div>

          {coverPosition === "RIGHT" ? (
            <div className="col-12 col-md-6 mb-4 mb-md-0">
              <img alt="cover" className="img-fluid" src={imageUrl(cover)} />
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default SectionDefault;
