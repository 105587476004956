import React from "react";
import style from "./parallax.scss";
import { imageUrl } from "../../utils/util";

const Parallax = ({ data = {} }) => {
  const { cover, contentId } = data;

  return (
    <section id={contentId} className="fdb-block fdb-viewport bg-dark parallax">
      <img className="img-fluid full-width" src={imageUrl(cover)} alt="" />
    </section>
  );
};

export default Parallax;
