export function isEmpty(objectToCheck) {
    if(!objectToCheck) return true;
    return Object.entries(objectToCheck).length === 0
}

export function imageUrl(image) {
    return `${process.env.REACT_APP_BASE_URL}/image/${image}`;
}

export function chunkArray(myArray, chunk_size) {
    const arrayLength = myArray.length;
    const tempArray = [];
    for (let index = 0; index < arrayLength; index += chunk_size) {
        const myChunk = myArray.slice(index, index+chunk_size);
        tempArray.push(myChunk);
    }
    return tempArray;
};