import React from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import Concert from "../concert";

const SeriesDetail = ({ show, onHide, series = {} }) => {
  const { title, subtitle, concertList = [] } = series;

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="serie-modal-header" closeButton>
        <h3 className="modal-title">
          {title} - {subtitle}
        </h3>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            {concertList.map((concert, index) => (
              <Col lg={6} key={`concert_${index}`}>
                <Concert concert={concert} />
              </Col>
            ))}
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default SeriesDetail;
