import React, { useState } from "react";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";

import { chunkArray } from "../../utils/util";
import ReactHtmlParser from "react-html-parser";
import { Container, Modal } from "react-bootstrap";
import api from "../../utils/api";

const MailMessage = ({ show, onHide, message }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <Container>
          <p>{message}</p>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

const Contacts = ({ data = {} }) => {
  const {
    title,
    subtitle,
    description,
    note,
    inputs = [],
    privacyPolicy,
    buttonLabel,
    successMessage,
    errorMessage,
    page,
    key,
    contentId,
  } = data;
  const [modalShow, setModalShow] = useState(false);
  const [message, setMessage] = useState(undefined);

  const chunkInputs = chunkArray(inputs, 2);

  const inputElems = chunkInputs.map((chunk) => {
    return (
      <div className="row" key={uuidv4()}>
        {chunk.map((elem, index) => {
          const wrapperClasses = classNames("col-12 col-md mt-4");
          return (
            <div className={wrapperClasses} key={uuidv4()}>
              <input
                type="text"
                name={elem.fieldName}
                className="form-control"
                placeholder={elem.placeholder}
              />
            </div>
          );
        })}
      </div>
    );
  });

  const formToJSON = (elements) =>
    [].reduce.call(
      elements,
      (data, element) => {
        data[element.name] = element.value;
        return data;
      },
      {}
    );

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = document.forms.namedItem("contactUs");
    const formData = formToJSON(form.elements);

    const data = await api.post(`/page/contact-us`, formData, {
      headers: {
        "X-TENANT-ID": window.tenant,
      },
    });

    if (data.status === 200) {
      setMessage(successMessage);
    } else {
      setMessage(errorMessage);
    }

    setModalShow(true);
  };

  return (
    <section id={contentId} className="fdb-block">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <h2 className="block-title">{title}</h2>
            <h4 className="block-subtitle">{subtitle}</h4>
            <div className="block-detail mt-5">
              {ReactHtmlParser(description)}
            </div>
          </div>
        </div>
        <div className="row pt-4">
          <div className="col-12">
            <form name="contactUs">
              {inputElems}
              {privacyPolicy && (
                <div className="row mt-4">
                  <div className="col">{ReactHtmlParser(privacyPolicy)}</div>
                </div>
              )}
              <div className="row">
                <div className="col">
                  <button
                    onClick={handleSubmit}
                    className="btn btn-default"
                    style={{
                      backgroundColor: page.color,
                    }}
                  >
                    {buttonLabel}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="row pt-4">
          <div className="col-12 col-md-8">{ReactHtmlParser(note)}</div>
        </div>
      </div>
      <MailMessage
        show={modalShow}
        onHide={() => setModalShow(false)}
        message={message}
      />
    </section>
  );
};

export default Contacts;
