import React from "react";
import { v4 as uuidv4 } from "uuid";

const Testimonials = ({ data = {} }) => {
  const { references = [], contentId } = data;

  const referenceElems = references.map((reference) => (
    <a href={reference.webSite} target="_blank" key={uuidv4()}>
      <img
        alt="reference"
        height="40"
        className="ml-3 mr-3 mb-2 mt-2"
        src={reference.logoUrl}
      />
    </a>
  ));

  return (
    <section id={contentId} className="fdb-block bg-dark">
      <div className="container">
        <div className="row justify-content-center text-center block-detail">
          <div className="col">{referenceElems}</div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
