import React from 'react';
import ReactDOM from 'react-dom';
import App from './pages/App';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import initializeApp from "./utils/appConfig";

const render = (Component) => {
    ReactDOM.render(
       <Component />,
        document.getElementById('root'),
    );
};

initializeApp();

render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
