import React from "react";
import Iframe from "react-iframe";
import ReactHtmlParser from "react-html-parser";
const Address = ({ data = {} }) => {
  const { title, subtitle, description, link, contentId } = data;
  return (
    <section id={contentId} className="fdb-block pt-0">
      {link && (
        <div className="container-fluid p-0 pb-5">
          <Iframe
            className="map"
            url={link}
            width="100%"
            height="300"
            frameBorder="0"
            style="border:0"
            allowFullScreen={true}
            position="relative"
          />
        </div>
      )}

      <div className="container">
        <div className="row justify-content-center pb-xl-5">
          <div className="col-12 col-md-8 text-center">
            <h2 className="block-title">{title}</h2>
            <h4 className="block-subtitle">{subtitle}</h4>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-12 col-md-8 text-center block-detail">
            {ReactHtmlParser(description)}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Address;
