import React, {Component} from "react";
import {v4 as uuidv4} from 'uuid';
import ReactGA from 'react-ga';

import './main.scss';
import Header from "../components/header";
import Banners from "../components/banner";

import Section from "../components/section";
import Address from "../components/address";

import Team from "../components/team";
import api from "../utils/api";
import BenefitBlock from "../blocks/benefit";
import Contacts from "../components/contacts";
import Footer from "../components/footer";
import Series from "../components/series";
import Testimonials from "../components/testimonials";
import Parallax from "../components/parallax";
import SubscriptionButton from "../components/buttons/SubscriptionButton";
import {imageUrl} from "../utils/util";
import Faq from "../components/faq";

const sectionMap = {
        "banner": Banners,
        "benefit": BenefitBlock,
        "seatingPlan": Section,
        "subscription": Section,
        "team": Team,
        "hall": Section,
        "address": Address,
        "header": Header,
        "contact": Contacts,
        "extras": Section,
        "footer": Footer,
        "series": Series,
        "testimonial": Testimonials,
        "parallax": Parallax,
        "faq": Faq
}

function renderSection(section = {}, page = {}) {
        const {data, ...rest} = section;
        const sectionData = {
                ...data,
                ...rest,
                page
        }
        const SectionComponent = sectionMap[section.key];
        return SectionComponent ? <SectionComponent key={uuidv4()} data={sectionData}/> : null;
}

class App extends Component {
        constructor(props) {
                super(props);
                this.state = {
                        isLoading: true,
                        page: null
                };

        }

        async componentDidMount() {
                const data = await api.get(`/page`, {
                        headers: {
                                'X-TENANT-ID': window.tenant
                        }
                });

                const page = data.data;
                this.setState({
                        ...this.state,
                        isLoading: false,
                        page
                });
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
                const favicon = document.getElementById('favicon');
                if(this.state.page) {
                        favicon.href = imageUrl(this.state.page.favicon);
                }
        }

        render() {
                const {isLoading, page} = this.state;

                if (isLoading) {
                        return <></>;
                }

                const {data} = page;

                const results = data.map((section => {
                        return renderSection(section, page)
                }));

                if (page && page.gaTrackingCode) {
                        ReactGA.initialize(page.gaTrackingCode);
                        ReactGA.pageview(window.location.pathname + window.location.search);
                }

                return (
                    <>
                            {results}
                            {page.subscriptionButton &&
                            <SubscriptionButton
                                label={page.subscriptionButton.label}
                                link={page.subscriptionButton.link}
                                color={page.color}
                            />
                            }
                    </>
                );
        }
}

export default App;