import React from "react";
import ReactHtmlParser from "react-html-parser";
import { v4 as uuidv4 } from "uuid";

import { Accordion, Card } from "react-bootstrap";

import "./faq.scss";

const renderFaq = (faq = {}, eventKey) => (
  <Card key={uuidv4()} className="faq-item">
    <Accordion.Toggle as={Card.Header} eventKey={eventKey}>
      <i className="fa fa-chevron-down mr-2 font-14"></i>
      {faq.question}
    </Accordion.Toggle>
    <Accordion.Collapse eventKey={eventKey}>
      <Card.Body> {ReactHtmlParser(faq.answer)}</Card.Body>
    </Accordion.Collapse>
  </Card>
);

const Faq = ({ data }) => {
  const { title, subtitle, faqs } = data;
  return (
    <section className="fdb-block">
      <div className="container faq">
        <div className="row text-center pb-xl-5">
          <div className="col-12">
            <h2 className="block-title">{title}</h2>
            <h4 className="block-subtitle">{subtitle}</h4>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 block-detail">
            <Accordion>
              {faqs.map((faq) => renderFaq(faq, uuidv4()))}
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
