import React from "react";
import "./header.scss";
import { imageUrl } from "../../utils/util";
import { Nav, Navbar } from "react-bootstrap";
import WebSiteSwitchButton from "../buttons/WebSiteSwitchButton";

const Header = ({ data }) => {
  const { page, headers } = data;
  const { websiteDropdown = { title: "Websites", websites: [] } } = page;
  return (
    <header className="navbar-fixed-top">
      <Navbar expand="md" className="no-gutters">
        <div className="col-2 text-center">
          <Navbar.Brand>
            <img src={imageUrl(page.logo)} alt="" />
          </Navbar.Brand>
        </div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-center col-md-8"
        >
          <Nav className="justify-content-center">
            {headers.map((header, index) => {
              return (
                <Nav.Link href={header.link} key={`header_${index}`}>
                  {header.label}
                </Nav.Link>
              );
            })}
          </Nav>
        </Navbar.Collapse>
        {websiteDropdown.websites.length > 0 && (
          <div className="justify-content-end col-md-2">
            <ul className="navbar-nav">
              <li className="nav-item">
                <WebSiteSwitchButton
                  title={websiteDropdown.title}
                  websites={websiteDropdown.websites}
                  color={page.color}
                />
              </li>
            </ul>
          </div>
        )}
      </Navbar>
    </header>
  );
};

export default Header;
