import React from "react";
import ReactHtmlParser from "react-html-parser";
import { imageUrl } from "../../utils/util";
import classNames from "classnames";

const SectionExtra = ({ section = {} }) => {
  const {
    title,
    subtitle,
    bgColor,
    titleColor,
    description,
    cover,
    coverPosition = "DOWN",
    shortCover,
    links = [],
    page,
    contentId,
  } = section;
  const styles = bgColor
    ? {
        backgroundColor: bgColor,
        color: titleColor,
      }
    : null;

  const buttons =
    links.length > 0 ? (
      <p className="mt-4">
        {links.map((item, index) => {
          const buttonClass = classNames("btn btn-default", {
            "ml-3": index > 0,
          });
          return (
            <a
              className={buttonClass}
              target="_blank"
              style={{
                backgroundColor: page.color,
              }}
              href={item.link}
              key={`link_${index}`}
            >
              {item.label}
            </a>
          );
        })}
      </p>
    ) : null;

  return (
    <section id={contentId} className="fdb-block pt-0 m-0" style={styles}>
      {cover && coverPosition === "UP" ? (
        <div className="row justify-content-center">
          <img alt="cover" className="img-fluid" src={imageUrl(cover)} />
        </div>
      ) : null}

      <div className="row mt-5 justify-content-center">
        <div className="col-12 col-md-8 text-center">
          <h2 className="block-title">{title}</h2>
          <h4 className="block-subtitle">{subtitle}</h4>
        </div>
      </div>

      <div className="row mt-5 justify-content-center">
        <div className="col-12 col-md-8 text-center block-detail">
          {ReactHtmlParser(description)}
          {shortCover && (
            <img
              alt="second_image"
              className="img-fluid mt-5"
              src={imageUrl(shortCover)}
            />
          )}
          {buttons}
        </div>
      </div>

      {cover && coverPosition === "DOWN" ? (
        <div className="row mt-5 justify-content-center">
          <img alt="cover" className="img-fluid" src={imageUrl(cover)} />
        </div>
      ) : null}
    </section>
  );
};

export default SectionExtra;
