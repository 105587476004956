import React from "react";
import Benefits from "../../components/benefit";

const BenefitBlock = ({ data }) => {
  const { title, subtitle, benefits, contentId } = data;

  return (
    <section id={contentId} className="fdb-block">
      <div className="container">
        <div className="row text-center">
          <div className="col-12">
            <h2 className="block-title">{title}</h2>
            <h4 className="block-subtitle">{subtitle}</h4>
          </div>
        </div>

        <Benefits benefits={benefits} />
      </div>
    </section>
  );
};

export default BenefitBlock;
