import React from "react";
import { imageUrl } from "../../utils/util";

const BannerItem = ({ banner }) => (
  <section className="fdb-block fdb-viewport bg-dark banner-item">
    <img className="img-fluid full-width" src={imageUrl(banner.cover)} alt="" />
  </section>
);

export default BannerItem;
