import React from "react";

const BenefitItem = ({ benefit }) => (
  <div className="col-12 col-md-4 pt-3 pt-sm-4 pt-md-0 mt-5">
    <div className="row">
      <div className="col-12">
        <h3>
          <strong>{benefit.title}</strong>
        </h3>
        <div className="block-detail">{benefit.description}</div>
      </div>
    </div>
  </div>
);

export default BenefitItem;
