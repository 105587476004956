import React from "react";

const handleWebSiteSelect = (event) => {
    window.location = event.target.value;
}

const WebSiteSwitchButton  = ({websites = [], color, title = 'Websites'}) => {
    let buttons;
    if(websites.length === 1) {
        buttons =   <a
            className="btn btn-default ml-md-3"
            style={{
                backgroundColor:color
            }}
            href={websites[0].address}
        >
            {websites[0].name}
        </a>
    } else {
        const items = websites.map(website => <option value={website.address}>{website.name}</option>)
        buttons = <select className="form-control" onChange={handleWebSiteSelect} style={{
                borderColor: color,
                color: color
            }}>
            <option value="">{title}</option>
            {items} </select>
    }
    return buttons;
};

export default WebSiteSwitchButton;