import React from 'react';
import SectionDefault from "./SectionDefault";
import SectionFill from "./SectionFill";
import SectionExtra from "./SectionExtra";

const Section = ({data}) => {
    const {fillCover, cover, coverPosition} = data;

    if(!cover || coverPosition === 'UP' || coverPosition === 'DOWN') {
        return <SectionExtra section={data} />
    } else {
        return fillCover ? <SectionFill section={data} /> : <SectionDefault section={data} />
    }
};

export default Section;
