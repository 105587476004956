import axios from "axios";

const initializeApp = () => {

    // axios.interceptors.request.use(
    //     config => {
    //         const token = auth.getAccessToken();
    //         if (token) {
    //             config.headers['Authorization'] = 'Bearer ' + token;
    //         }
    //         // config.headers['Content-Type'] = 'application/json';
    //         return config;
    //     },
    //     error => {
    //         Promise.reject(error)
    //     });

    //Add a response interceptor


};

export default initializeApp;