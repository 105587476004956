import React from 'react';

import ReactHtmlParser from "react-html-parser";
import {v4 as uuidv4} from 'uuid';

import './concert-card.scss';
import {imageUrl} from "../../utils/util";

const renderComposers = (composers) => (
    composers ? <p>{composers}</p> : null
)

const Concert = ({concert = {}}) => {

    const {name, date, dateText, composers, artistPhoto, artists = [], description} = concert;
    const photo = artistPhoto ? <img src={imageUrl(artistPhoto)} className="card-img-top" /> : null;
    return (
        <div className="card concert-card">
            {photo}
            <div className="card-body">
                <h3 className="card-title">{name}</h3>
                <h4 className="card-title">{dateText ? dateText: date }</h4>
                {renderComposers(composers)}
                {
                    artists.map((artist, index) => <p className="artist" key={uuidv4()}><strong>{artist.name}</strong> {artist.role}</p>)
                }
                <br/>
                <div className="concert-details">
                    {ReactHtmlParser(description)}
                </div>
            </div>
        </div>
    );
};

export default Concert;
